import { ReactElement, ReactNode } from 'react'

const Container = ({ hero, children }: { hero?: ReactElement; children?: ReactNode }) => {
    return (
        <>
            {hero}
            <div className="bg-i-shape bg-no-repeat bg-top-20 pt-6 pb-12">
                <div className="flex flex-col max-w-7xl p-4 sm:p-6 lg:p-8 mx-auto">{children}</div>
            </div>
        </>
    )
}

export default Container
