import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import Container from '@components/layout/Container'
import CTAButton from '@components/elements/CTAButton'

const ErrorPage = ({ statusCode }: { statusCode: number }) => {
    const { t } = useTranslation('common')

    return (
        <>
            <Head>
                <title>
                    {t('errors.error')} {statusCode}
                </title>
            </Head>
            <Container>
                <div className="flex justify-center h-120">
                    <div className="my-auto text-center space-y-6">
                        <div className="text-7xl font-extrabold">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-light to-primary-dark">
                                {t('errors.error')} {statusCode}
                            </span>
                        </div>
                        <div className="text-medium text-lg text-gray-500">{statusCode === 404 ? t('errors.404') : t('errors.500')}...</div>
                        <CTAButton className="mt-4" path="/" value={t('errors.back-to-homepage')} />
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ErrorPage
